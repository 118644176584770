/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ThemeProvider } from 'styled-components';
import { getTheme, product, getFonts } from "./styles/theme/themes";
import GlobalStyle, { IframePlaylist } from "./styles/global";
import Header from "./components/Header";
import Content from "./routes";
import WebFont from "webfontloader";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AxiosInterceptor } from "./service/Interceptors";
import { setInContent, setIsPlaylist, setPlaylist, setScrollPosition, setShowHeader, setShowHiddenMenu } from "./store/actions/app";
import { setIsLoggedIn, setLoggedOut } from "./store/actions/auth";
import { setUserData } from "./store/actions/user";
import { setLocation } from "./store/actions/location";
import { auth } from "./modules/auth";
import { playlists } from "./modules/playlist";
import Logger from "./modules/logger";
import userApi from "./modules/user";
import locationApi from "./modules/location";
import SubMenu from "./components/SubMenu";
import { usePersistedState, useResponsive } from "./helpers/HooksHelper";
import DateHelper from "./helpers/DateHelper";
import UserHelper from "./helpers/UserHelper";
import EnvHelper from "./helpers/EnvHelper";
import UrlHelper from "./helpers/UrlHelper";
import ExternalScriptsHelper from "./helpers/ExternalScriptsHelper";

const Footer = React.lazy(() => import("./components/Footer"));

const App = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { i18n: { language } } = useTranslation();
  const { pathname } = useLocation();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user.user);
  const searchParams = new URLSearchParams(useLocation().search);
  const campaign = searchParams.get('campaign');
  const { theme = null, token = null } = UrlHelper.getUrlQueryParams();
  const [appTheme, setAppTheme] = usePersistedState('theme', getTheme(theme));
  const { isPlaylist, inContent } = useSelector((state) => state.app);
  const { isMobile } = useResponsive();

  document.onreadystatechange = () => {
    if (document.readyState === "complete") {
      const homePaths = ['/home', '/home/', '/', `/${ language }`, `/${ language }/`];
      const time = homePaths.includes(pathname) ? 5000 : 0;
      setTimeout(() => {
        ExternalScriptsHelper.addElementsDynamically();
      }, time);
    }
  };

  useEffect(() => {
    const nVendedorID = searchParams.get('nVendedorID') || searchParams.get('nVendedorId');
    if (nVendedorID) window.sessionStorage.setItem('nVendedorID', nVendedorID);
  }, []);

  const loginPeer = async () => {
    UserHelper.removeBearerToken();
    const resp = await auth.loginPeer(token);
    if (resp.data.auth) {
      checkLoginPlataform({
        token: resp.data.auth.access_token,
        expires_in: resp.data.auth.expires_in
      });
    }
  };

  const checkLoginPlataform = async (tokenObj = {}) => {
    if (tokenObj.token) {
      await userApi.setTokenOnLocalStorage({
        access_token: tokenObj.token,
        expires_in: tokenObj.expires_in
      });
    }
    try {
      const resp = await auth.checkForInitialInfo(tokenObj);
      if (resp && resp.success) {
        dispatch(setIsLoggedIn(UserHelper.isLogged()));
        dispatch(
          setUserData({
            id: resp.data.id,
            UUID: resp.data.UUID,
            name: resp.data.firstName,
            lastName: resp.data.lastName,
            email: resp.data.email,
            bUserHasCourses: resp.data.bUserHasCourses,
            phone: resp.data.cellPhone,
            billingCountry: resp.data.billingCountry,
          })
        );
        history.push(window.location.pathname);
      } else {
        dispatch(setLoggedOut());
        history.push(window.location.pathname + window.location.search);
      }
      // eslint-disable-next-line no-empty
    } catch (ex) {
    }
  }

  const doAliveLog = () => {
    let nUserID = user?.id;
    if (!nUserID) nUserID = null;

    const brazilDateTime = DateHelper.getDateFromTimezone('America/Sao_Paulo');

    const body = {
      timestamp: new Date(brazilDateTime).getTime(),
      hardwareID: window.localStorage.getItem('hardwareID') || UserHelper.getHardwareID(),
      nUserID,
      browser: navigator.userAgent,
      type: 'ALIVE-TRACK',
      developer: process.env.REACT_APP_ENVIRONMENT === 'development',
      product,
    };

    Logger.doIndexedDBLog(body);
  }
  clearInterval(window.doAliveLog);
  window.doAliveLog = setInterval(doAliveLog, 60000);

  const doStatisticLog = () => {
    Logger.doLogStatistics();
  }
  clearInterval(window.doStatisticLog);
  if (!Logger.isStatisticsLogTableEmpty()) doStatisticLog();
  window.doStatisticLog = setInterval(doStatisticLog, 60000 * 5);

  useEffect(() => {
    if (theme) setAppTheme(getTheme(theme))
  }, [theme]);

  document.addEventListener('loadFonts', () => {
    WebFont.load({
      google: {
        families: getFonts(),
      },
      timeout: 2000,
    });
  });

  const wppTimeValidate = () => {
    const dateNow = new Date();
    const utcHourNow = dateNow.getUTCHours();
    if (utcHourNow >= 12 && utcHourNow < 21) {
      if (dateNow.getDay() > 0 && dateNow.getDay() < 6) return true;
    }
    return false;
  }

  useEffect(() => {
    function verifyBottomRightButtonsPosition() {
      const whatsAppButton = document.querySelector('#whatsapp-iframe') || null;
      const zdbutton = document.querySelector('#zdbutton') || null;
      const launcher = document.querySelector('#launcher') || null;
      const webWidget = document.querySelector('#webWidget') || null;
      const isCourseLp = window.location.pathname.includes("courselp");
      const footerHeight = document.getElementsByTagName('footer')[0]?.clientHeight;

      const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight
      if (window.scrollY >= (scrollableHeight - footerHeight)) {
        if (whatsAppButton) whatsAppButton.style.bottom = '85px';
        if (zdbutton) zdbutton.style.bottom = '75px';
        if (launcher) launcher.style.bottom = '75px';
        if (webWidget) webWidget.style.bottom = '75px';
      } else {
        if (whatsAppButton) whatsAppButton.style.bottom = isCourseLp ? '85px' : '15px';
        if (zdbutton) zdbutton.style.bottom = isCourseLp ? '75px' : '15px';
        if (launcher) launcher.style.bottom = isCourseLp ? '75px' : '15px';
        if (webWidget) webWidget.style.bottom = isCourseLp ? '75px' : '15px';
      }
    }

    if (!EnvHelper.isPeer()) checkLoginPlataform();
    else if (token) loginPeer();

    document.addEventListener('scroll', verifyBottomRightButtonsPosition);

    setTimeout(() => {
      verifyBottomRightButtonsPosition();
    }, 1000);

    return () => {
      document.removeEventListener('scroll', verifyBottomRightButtonsPosition);
    }
  }, []);

  useEffect(() => {
    if (auth.isTokenExpired()) {
      UserHelper.removeBearerToken();
      dispatch(setLoggedOut());
      history.push(`/${language}`);
    }

    if (!EnvHelper.isInvestMe() && !EnvHelper.isWL()) {
      const zeIframe = document.getElementById('launcher');
      const whatsApp = document.querySelector('#whatsapp-iframe');

      if (pathname.match(/(\/courses\/|\/lives\/)/i) !== null) {
        if (whatsApp) whatsApp.style.display = 'none';
        if (zeIframe) window.$zopim.livechat.hideAll();
      } else {
        if (wppTimeValidate()) {
          if (zeIframe) window.$zopim.livechat.hideAll()
          // document.querySelector('#whatsapp-iframe').style.display = 'initial';
        } else {
          if (zeIframe) window.$zopim.livechat.button.show();
          if (whatsApp) whatsApp.style.display = 'none';
        }
      }
    }
  }, [pathname]);

  useEffect(() => {
    const acceptedUris = EnvHelper.acceptedUri();
    function listenMessage(event) {
      if (acceptedUris.find(el => el.includes(event.origin)) && EnvHelper.isInvestMe()) {
        if (event.data === 'scrollBlock') dispatch(setScrollPosition(true));
        if (event.data === 'scrollHidden') dispatch(setScrollPosition(false));
        if (event.data === 'inContent' || event.data === 'outContent') {
          dispatch(setInContent(event.data === 'inContent'));
        }
        if (event.data === 'showSubMenu') dispatch(setShowHiddenMenu(true));
        if (event.data === 'hiddenSubMenu') dispatch(setShowHiddenMenu(false));
        if (event.data.name === 'router') {
          history.push(`/${language}/${event.data.to}`)
          dispatch(setIsPlaylist(false))
        }
      }
    }
    if (EnvHelper.isInvestMe()) getPlaylistToSearch();
    if (EnvHelper.isInvestMe()) window.addEventListener('message', listenMessage);
  }, []);

  useEffect(() => {
    if (EnvHelper.isInvestMe() && isLoggedIn) {
      dispatch(setShowHeader(true))
      if (isPlaylist) {
        document.getElementById('playlistIframe').style.display = 'block';
        document.getElementById('reactParent').style.display = 'none';
      } else {
        document.getElementById('playlistIframe').style.display = 'none';
        document.getElementById('reactParent').style.display = 'block';
      }
    }
  }, [isPlaylist, isMobile, EnvHelper.isInvestMe()]);

  const getPlaylistToSearch = async () => {
    const resp = await playlists.searchPlaylist();
    if (!resp.success) {
      dispatch(setPlaylist([]));
    } else {
      dispatch(setPlaylist(resp.data));
    }
  }

  const getIframePlaylist = () => {
    const token = UserHelper.getBearerToken();
    const url = `${process.env.REACT_APP_PLAYLIST_URL}?canUserAccess=true&theme=newb3&token=${token}`;
    return (
      <IframePlaylist loading="eager" id="playlistIframe" src={url}
        style={{
          width: '100%',
          height: '100vh',
          border: 'none',
          display: 'none',
          position: 'fixed'
        }}
      />
    )
  }

  useEffect(() => {
    locationApi.detectCountry()
      .then((location) => {
        dispatch(setLocation(location));
      })
  }, []);

  return (
    <ThemeProvider theme={appTheme}>
      <AxiosInterceptor />
      <div className="App">
        <GlobalStyle />
        {campaign !== 'mbaxp' && <Header url={match.url} />}
        {
          !inContent
          && !isPlaylist
          && (pathname.endsWith("/lives") ||
            pathname.endsWith("/home") ||
            pathname.endsWith("/courses") ||
            (EnvHelper.isInvestMe() && pathname.endsWith("/"))) &&
          <SubMenu />
        }
        <div id="reactParent">
          <Content match={match} />
        </div>
        {EnvHelper.isInvestMe() && isLoggedIn && getIframePlaylist()}
        <React.Suspense fallback={<div/>}>
          <Footer isWhite={pathname.endsWith("/login") || pathname.endsWith("/password-recovery") || pathname.endsWith("/") || pathname.includes("/recuperacao-senha")}/>
        </React.Suspense>
      </div>
    </ThemeProvider>
  );
};

export default App;
